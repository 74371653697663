import variable from './../../scss/_variables.scss';
import { TableCell, TableRow } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const AdminTR = styled(TableRow)((props) => ({
    '&:nth-of-type(even)': {
        backgroundColor: variable.magnolia,
        borderRadius: 8
    },
    border: 0
}))
const AdminTH = styled(TableCell)((props) => ({
    backgroundColor: variable.magnolia,
    color: '#263238CF',
    fontSize: 16,
    fontWeight: 600,
    border: 0,
    fontFamily: 'Poppins',
    whiteSpace: 'nowrap',
    '&:first-child': {
        borderRadius: '8px 0 0 8px'
    },
    '&:last-child': {
        borderRadius: '0 8px 8px 0'
    },
}))
const AdminTD = styled(TableCell)((props) => ({
    border: 0,
    whiteSpace: 'nowrap',
    fontFamily: 'Poppins',
    fontSize: 16,
    '&:first-child': {
        borderRadius: '8px 0 0 8px'
    },
    '&:last-child': {
        borderRadius: '0 8px 8px 0'
    },
}))

export { AdminTH, AdminTR, AdminTD }