import React, { useState, useEffect, useRef, useCallback } from 'react';
import emptyNotification from './../../assets/images/icons/empty-notification.png';
import { Link } from 'react-router-dom';
import announcementIcon from '../../assets/images/icons/announcement.png'
import notificationIcon from '../../assets/images/icons/notification-type-icon.svg'
import assignmentNotificationIcon from '../../assets/images/icons/assignment-notification.png'
import parse from 'html-react-parser';
import { boldBanglaNumber } from '../../utilities/boldBanglaNumber';
// import seenNotificationIcon from '../../assets/images/icons/seen-annoucement.png'

import moment from "moment";
import { useSelector } from 'react-redux'
import firebase from "firebase/app";

// Cart Preview
const NotificationPreview = (props) => {

    const [notificationList, setNotificationList] = useState([]);

    const userData = useSelector(state => state.user).data;
    const [isLoading, setIsLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    const observer = useRef();
    const lastNotificationElementRef = useCallback(
        (node) => {
          if (isLoading) return;
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                // console.log('FIREBASE_LOG lastNotificationElementRef --> isIntersecting')
                fetchNotification()
            }
          });
          if (node) observer.current.observe(node);
        },
        [isLoading, hasMore]
      );

    const fetchNotification = (initialFetch = false) => {
        if (userData?.email && userData?.role === "student") {

            let notificationTopic = [...userData.notificationTopic];

            if (notificationTopic?.length === 0) {
              notificationTopic.push("unPurchase");
            }

            notificationTopic.push("all");
            notificationTopic.push(userData?._id);

            const lastVisible = notificationList[notificationList.length - 1];
            // console.log('lastVisible', lastVisible)

          setIsLoading(true)
            const db = firebase.firestore().collection("notification");
            let query;

            if (lastVisible == null || initialFetch) {
                query = db.where('topic', 'array-contains-any', notificationTopic)
                    .orderBy("time", "desc")
                    .limit(10);
            } else {
                query = db.where('topic', 'array-contains-any', notificationTopic)
                    .orderBy("time", "desc")
                    .startAfter(lastVisible.time)
                    .limit(10);
            }

            query.get().then(function (querySnapshot) {

                setIsLoading(false)
                let tempList = [];
                querySnapshot.forEach(function (doc) {
                    tempList.push({
                        _id: doc?.id,
                        ...doc.data()
                    });

                });

                if (initialFetch) {
                  setNotificationList(tempList)
                } else {
                  setNotificationList([
                    ...notificationList,
                    ...tempList
                  ]);
                }

            }, function (error) {
                console.log("firebase error", error)
            });
        }

    }

    useEffect(() => {
        if (props.notificationToggle) {
            // console.log('FIREBASE_LOG useEffect notificationToggle')
            setNotificationList([])
            fetchNotification(true)
        }
    }, [props.notificationToggle])


    return (
        <div className="cart notifications">
            <div>
                {(notificationList?.length > 0) ?

                    <div className="cart-items-wrapper pt-2">
                        <div className="items" style={{ overflowY: "scroll", height: 284 }}>
                            {
                                notificationList.map((notification, index) => {
                                    if (index === notificationList.length - 1) {
                                        return (
                                            <Link exact to={notification?.type === "announcement" ? `/announcements?redirect=${notification?.announcementId}` : (notification?.redirect ? "/" + notification?.redirect : '#')}
                                            query={{ notification }}
                                            key={notification?._id}
                                            ref={lastNotificationElementRef}
                                            className={`d-flex align-items-start px-4 py-2  ${(notification?.type === 'notification' && !notification?.redirect) ? 'prevent-redirect' : ''} `}
                                            >
                                                <img src={notification?.type === "announcement" ? announcementIcon : notification.title === "New assignment has been released" ? assignmentNotificationIcon : notificationIcon} alt="" />
                                                <div className="ml-2">
                                                    <h5 className="mb-1">{parse(boldBanglaNumber(notification.title))}</h5>
                                                    {<small className="mb-1">{parse(boldBanglaNumber(notification.title)) === "Welcome!" ? "To Phitron" : moment(notification.time).fromNow()}</small>}

                                                </div>
                                            </Link>
                                        )
                                    } else {
                                        return (
                                            <Link exact to={notification?.type === "announcement" ? `/announcements?redirect=${notification?.announcementId}` : (notification?.redirect ? "/" + notification?.redirect : '#')}
                                            query={{ notification }}
                                            key={notification?._id}
                                            className={`d-flex align-items-start px-4 py-2  ${(notification?.type === 'notification' && !notification?.redirect) ? 'prevent-redirect' : ''} `}
                                            >
                                                <img src={notification?.type === "announcement" ? announcementIcon : notification.title === "New assignment has been released" ? assignmentNotificationIcon : notificationIcon} alt="" />
                                                <div className="ml-2">
                                                    <h5 className="mb-1">{parse(boldBanglaNumber(notification.title))}</h5>
                                                    {<small className="mb-1">{parse(boldBanglaNumber(notification.title)) === "Welcome!" ? "To Phitron" : moment(notification.time).fromNow()}</small>}

                                                </div>
                                            </Link>
                                        )
                                    }
                                })
                            }
                            <div className='d-flex align-items-center px-4 py-2'>{isLoading && "Loading..."}</div>

                        </div>
                    </div>
                    :
                    <div className="text-center py-4 cart-wrapper">

                        <img src={emptyNotification} alt="" />
                        <p className="mt-4 px-5" style={{ fontWeight: 'normal', whiteSpace: 'nowrap' }}>No Notification for you</p>

                    </div>
                }
            </div>
        </div>
    );
};

export default NotificationPreview;
