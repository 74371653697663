import { Container, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const ViewModalSkeleton = ({ count = 9 }) => {
    return (
        <Container>
            <Grid className='py-4' container spacing={4}>
                {
                    [...new Array(count)].map(() => (
                        <Grid item md={4} sm={6} xs={12}>
                            <Skeleton variant='text' animation="wave" height={20} />
                            <Skeleton className='mt-2' variant='text' animation="wave" height={20} />
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    );
};

export default ViewModalSkeleton;