// optionalCourse reducer

const initialState = {
    currentUnit: null,
    lockedModules: [],
    optionalCourseUnlockedModules: [],
    status: "idle",
    error: null,
  };
  
  const optionalCourse = (state = initialState, action) => {
    switch (action.type) {
      case "LOAD_OPTIONAL_COURSE/PENDING":
        return {
          ...state,
          error: null,
          status: "pending",
          lockedModules: [],
          optionalCourseUnlockedModules: [],
        };
      case "LOAD_OPTIONAL_COURSE/SUCCESS":
        return {
          ...state,
          error: null,
          status: "success",
          lockedModules: action.payload.lockedModules,
          optionalCourseUnlockedModules: action.payload.optionalCourseUnlockedModules,
        };
      case "LOAD_OPTIONAL_COURSE/ERROR":
        return {
          ...state,
          error: action.payload,
          status: "error",
          lockedModules: [],
          optionalCourseUnlockedModules: [],
        };
      case "SET_CURRENT_OPTIONAL_COURSE_UNIT":
        return {
          ...state,
          currentUnit: action.payload,
        };
      case "UNLOCK_OPTIONAL_COURSE_MODULE": {
        const moduleToUnlock = state.lockedModules.find(
          (module) => module._id === action.payload
        );
        const lockedModules = state.lockedModules.filter(
          (module) => module._id !== moduleToUnlock._id
        );
        const optionalCourseUnlockedModules = [moduleToUnlock, ...state.optionalCourseUnlockedModules];
        return {
          ...state,
          lockedModules,
          optionalCourseUnlockedModules,
        };
      }
  
      default:
        return state;
    }
  };
  
  export default optionalCourse;
  