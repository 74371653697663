import { faClock, faMinus } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import fbGroup from "../../assets/images/icons/facebook-group.gif";
import store from "../../store";
import preFetchPage from "../../utilities/preFetchPage";
// import MessengerCustomerChat from "react-messenger-customer-chat";
import { MessengerChat } from "react-messenger-chat-plugin";
import { WEBSITE_VERSION } from "../../siteSettings";
import call from "./../../assets/icons/homepage/footer/call.svg";
import facebook from "./../../assets/icons/homepage/footer/facebook.svg";
import playStore from "./../../assets/icons/homepage/footer/google-play.png";
import instagram from "./../../assets/icons/homepage/footer/instagram.svg";
import linkedin from "./../../assets/icons/homepage/footer/linkedin.svg";
import location from "./../../assets/icons/homepage/footer/location.svg";
import mail from "./../../assets/icons/homepage/footer/mail.svg";
import youtube from "./../../assets/icons/homepage/footer/youtube.svg";
import logoWhite from "./../../assets/images/homepage/logo-white.png";
import paymentImg from "./../../assets/images/homepage/payments.png";
// Footer Component
const Footer = () => {
  const {
    address,
    email,
    phone = "",
    phoneAvailable,
  } = useSelector((state) => state.footerData) || {};
  const phoneNumbers = phone?.split(",");

  return (
    <section className="footer-component px-3 pb-4">
      <div className="container text-white">
        <div className="row py-4 mt-1">
          <div className="col-md-5 pr-md-0">
            <div className="mb-3">
              <img src={logoWhite} alt="" />
            </div>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center align-items-md-start justify-content-center justify-content-md-start flex-column flex-md-row">
                <span className="mx-1">
                  <img src={location} alt="" />
                </span>
                {address}
              </li>
              <li className="d-flex align-items-center align-items-md-start justify-content-center justify-content-md-start flex-column flex-md-row">
                <span className="mx-1">
                  <img src={mail} alt="" />
                </span>
                <span className="d-flex">
                  Official:
                  <a className="text-white ml-1" href={`mailto:${email}`}>
                    {email}
                  </a>
                </span>
              </li>
              <li className="d-flex align-items-center align-items-md-start justify-content-center justify-content-md-start flex-column flex-md-row">
                <span className="mx-1">
                  <img src={call} alt="" />
                </span>
                <span>
                  Helpline :
                  {phoneNumbers.map((phone, i, arr) => (
                    <React.Fragment key={i}>
                      {" "}
                      <a
                        key={phone}
                        className="text-white"
                        href={`tel:${phone}`}
                      >
                        {phone?.trim()}{" "}
                      </a>{" "}
                      {i !== arr.length - 1 && ","}{" "}
                    </React.Fragment>
                  ))}
                  <span style={{ width: "unset", fontSize: 14 }}>
                    (Available : {phoneAvailable})
                  </span>{" "}
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 footer-column">
            <h3 className="heading">Useful Links</h3>
            <ul className="list-unstyled">
              <li>
                <Link
                  onMouseEnter={() => preFetchPage("staticPage")}
                  className="text-white"
                  to="/refund-policy"
                >
                  {" "}
                  Refund policy{" "}
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={() => preFetchPage("termsOfService")}
                  className="text-white"
                  to="/terms-of-service"
                >
                  {" "}
                  Terms and Conditions{" "}
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={() => preFetchPage("staticPage")}
                  className="text-white"
                  to="/privacy-policy"
                >
                  {" "}
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={() => preFetchPage("staticPage")}
                  className="text-white"
                  to="/app-privacy-policy"
                >
                  {" "}
                  App Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={() => preFetchPage("about")}
                  to="/about-us"
                  className="text-white"
                >
                  About us
                </Link>
              </li>
              {/* <li>
                <Link
                  onMouseEnter={() => preFetchPage("success")}
                  className="text-white"
                  to="/"
                >
                Success
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-md-4 footer-column">
            <h3 className="heading">Social Media Link</h3>
            <ul className="list-unstyled">
              <li className="mt-4">
                <ul className="list-inline social-icons">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/phitron.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={facebook} alt="" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/phitron.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={instagram} alt="" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/phitron/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.youtube.com/@phitron"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={youtube} alt="" />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <p className="m-0">Download App</p>
            <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
              <a
                className="mr-2 mt-2"
                href="https://play.google.com/store/apps/details?id=learn.programming.courses.phitron"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={playStore} alt="" />
              </a>
              {/* <a className="mt-2" href="#" target="_blank" rel="noopener noreferrer">
                <img src={appStore} alt="" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="text-center">
          <img src={paymentImg} className="img-fluid mb-3" alt="" />
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="text-center text-md-left bin-id">
              <small>Trade License: 177159</small>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center" style={{ color: "#CDCDCD" }}>
              <small>Copyright © {new Date().getFullYear()} Phitron.io</small>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="text-center text-md-right bin-id">
              <small>v {WEBSITE_VERSION}</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Popup = () => {
  const [time, setNewTime] = useState(moment().format("HH"));
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state?.user?.data) || {};
  const {
    phoneAvailable,
    phone = "",
    showPhonePopup,
  } = useSelector((state) => state.footerData) || {};
  useEffect(() => {
    setNewTime(moment().format("HH"));
    setTimeout(() => {
      setShow(false);
    }, 30000);
  }, []);

  const phoneNumbers = phone?.split(",");
  return (
    <div>
      {time >= 9 &&
        time < 19 &&
        showPhonePopup &&
        !user?.parchasedCourse?.length &&
        (!user?.role || user.role === "student") && (
          <div>
            <div className="contact-popup">
              {show && (
                <div className={show ? "body show" : "body"}>
                  <div className="header">
                    <div className="close" onClick={() => setShow(!show)}>
                      <FontAwesomeIcon icon={faMinus} />
                    </div>
                  </div>
                  <img
                    className="popup-logo"
                    src={require("../../assets/images/black-logo.png")}
                    alt=""
                  />
                  <p className="help-tag">
                    তোমার কোনো হেল্প লাগলে আমাদেরকে জানাও!
                  </p>
                  <ul className="list-unstyled">
                    {phoneNumbers.map((number, i, arr) => (
                      <li key={Number}>
                        <a href={`tel:${number}`}>{number?.trim()}</a>
                        {i !== arr.length - 1 && <span>, </span>}
                      </li>
                    ))}
                  </ul>
                  <p>
                    <FontAwesomeIcon icon={faClock} />{" "}
                    <span>Available : {phoneAvailable}</span>
                  </p>
                  <div className="text-left mt-2">
                    <a
                      target="_blank"
                      rel="norefferer"
                      href="https://web.facebook.com/groups/programmingherocommunity"
                    >
                      <img className="w-50 img-fluid" src={fbGroup} alt="..." />
                    </a>
                  </div>
                </div>
              )}
              <div className="icon" onClick={() => setShow(!show)}>
                <img
                  src={require("../../assets/images/icons/call.png")}
                  alt="..."
                />
              </div>
              {!show && (
                <div
                  className="icon-alt d-block d-md-none"
                  onClick={() => setShow(!show)}
                >
                  <img
                    src={require("../../assets/images/icons/call.png")}
                    alt="..."
                  />
                </div>
              )}
            </div>
          </div>
        )}

      {/* ((!(time >= 9 && time < 19) || !showPhonePopup) && !user?.parchasedCourse?.length && (!user?.role || user.role === 'student')) && */}
      {
        // <div>
        //   <MessengerCustomerChat
        //     pageId="101232889184593"
        //     // appId="<APP_ID>"
        //     // htmlRef="<REF_STRING>"
        //     greetingDialogDisplay="hide"
        //   />
        // </div>
        <div>
          <MessengerChat
            pageId="101232889184593"
            greetingDialogDisplay={"hide"}
          />
        </div>
      }
    </div>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Popup />
  </Provider>,
  document.getElementById("contact-root")
);

export default Footer;
