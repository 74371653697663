import axios from "axios";
import authService from "../../authService";
const API_URL = process.env.REACT_APP_API_URL;

const getAuthorization = () => {
    // Getting user token and set to localstorage
    const token = authService.getCurrentUser() && authService.getCurrentUser().token
    axios.defaults.headers.common = { 'Authorization': `${token}` }
}

class BatchService {
    constructor() {
        getAuthorization();
    }

    // Create/Post batch
    createBatch = (formData) => {
        getAuthorization();
        return axios.post(API_URL + "batch", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

    };

    // Get list of batch
    getBatches = (payload) => {
        return axios.get(API_URL + "batch", {
            params: {
                ...payload
            }
        })
    };
    // Search course
    getBatchSearch = (payload) => {
        return axios
            .get(API_URL + 'batch', {
                params: {
                    ...payload
                }
            })
    }
    // get All batch list

    getBatchList = (payload) => {
        return axios.get(API_URL + "batch/all/list", {
            params: {
                ...payload
            }
        })
    }

    // Get course info by ID
    getBatchDetails = (id) => {
        return axios
            .get(API_URL + `batch/detail/${id}`)
    }

    // Update course info
    patchBatch = (id, payload) => {
        return axios
            .patch(API_URL + `batch/${id}`, payload)
    }

    // create semester
    createSemester = (payload) => {
        return axios.post(API_URL + 'semester', payload)
    }

    // Get list of semester 
    getSemesters = (payload) => {
        return axios.get(API_URL + "semester", {
            params: {
                ...payload
            }
        })
    };

    // get All semester list
    getSemesterList = (payload) => {
        return axios.get(API_URL + "semester/all/list", {
            params: {
                ...payload
            }
        })
    }

    // Get semester info by ID
    getSemesterDetails = (id) => {
        return axios
            .get(API_URL + `semester/${id}`)
    }

    // Update semester info
    patchSemester = (id, payload) => {
        return axios
            .patch(API_URL + `semester/${id}`, payload)
    }

    // create base course
    createBaseCourse = (payload) => {
        return axios.post(API_URL + 'course/base-course', payload)
    }
    // Get base courses
    getBaseCourses = (payload) => {
        return axios.get(API_URL + "course/base-course", {
            params: {
                ...payload
            }
        })
    };
    // Get list of all base courses 
    getBaseCourseList = () => {
        return axios.get(API_URL + "course/base-course-list")
    };
    // Get list of all base courses 
    getBaseCourseInfo = (payload) => {
        return axios.get(API_URL + "course/base-course-list", {
            params: {
                ...payload
            }
        })
    };
    // Get base course details
    getBaseCourseDetails = (id) => {
        return axios.get(API_URL + `course/base-course/${id}`)
    }

    // Update base course info
    patchBaseCourse = (id, payload) => {
        return axios
            .patch(API_URL + `course/base-course/${id}`, payload)
    }

    // Create course
    createCourse = (formData) => {
        getAuthorization();
        return axios.post(API_URL + "course/addcourse", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

    };

    // create semester enroll
    createSemesterEnroll = (payload) => {
        return axios.post(API_URL + 'semester/enroll/student', payload)
    };
    // add marks distribution
    createMarksDistribution = (payload) => {
        return axios.post(API_URL + 'mark-distribution', payload)
    }

    // Get list of marks distribution
    getMarksDistributionList = (payload) => {
        return axios.get(API_URL + "mark-distribution", {
            params: {
                ...payload
            }
        })
    };
    // Get marks distribution deetails
    getMarksDistributionDetails = (id) => {
        return axios.get(API_URL + `mark-distribution/${id}`)
    }
    // update marks distribution
    patchMarksDistribution = (id, payload) => {
        return axios.patch(API_URL + `mark-distribution/${id}`, payload)
    }
    // Delete marks distribution
    deleteMarksDistribution = (id) => {
        return axios.delete(API_URL + `mark-distribution/${id}`)
    }

    // Get list of grade points
    getGradePointList = (payload) => {
        return axios.get(API_URL + "grade", {
            params: {
                ...payload
            }
        })
    };

    // add grade point
    createGradePoint = (payload) => {
        return axios.post(API_URL + 'grade', payload)
    }
    // Get grade point detail
    getGradePointDetail = (id) => {
        return axios.get(API_URL + `grade/${id}`)
    };

    // update grade point
    patchGradePoint = (id, payload) => {
        return axios.patch(API_URL + `grade/${id}`, payload)
    }
    // Delete grade point
    deleteGradepoint = (id) => {
        return axios.delete(API_URL + `grade/${id}`)
    };

    // get All semester module list
    getSemesterModuleList = () => {
        return axios.get(API_URL + "semester/module/list");
    }

    // get semester summary
    getSemesterSummary = () => {
        return axios.get(API_URL + "semester/summery/overview");
    }

    getBatchListDynamicPropery = (payload) => {
        return axios.get(API_URL + "batch/batch-list-dynamic-property", {
            params: {
                ...payload
            }
        })
    };


    //========= course Outline===========//

    // create course Outline
    createCourseOutline = (payload) => {
        return axios.post(API_URL + 'course/add-course-outline', payload)
    }
    // Get course Outline list
    getCourseOutlineList = (payload) => {
        return axios.get(API_URL + "course/get-course-outline-list", {
            params: {
                ...payload
            }
        })
    };


    // Get single course Outline
    getSingleCourseOutline = (id) => {
        return axios.get(API_URL + `course/get-course-outline/${id}`)
    }

    // Update course Outline
    updateCourseOutline = (id, payload) => {
        return axios.patch(API_URL + `course/course-outline/${id}`, payload)
    }

    // delete course Outline
    deleteCourseOutline = (id) => {
        return axios.delete(API_URL + `course/course-outline/${id}`)
    }

};

export default new BatchService();
