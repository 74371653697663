import { useLayoutEffect } from "react";

const useTitle = (title) => {
  useLayoutEffect(() => {
    const preTitle = document.title;
    if (title) {
      document.title = title;
    }

    return () => {
      if (title) {
        document.title = preTitle;
      }
    };
  }, [title]);
};

export default useTitle;
