import React from "react";
import Lottie from "react-lottie";

export default function LottieAnimation({ lottie, width, height, loop = true }) {
  const defaultOptions = {
    loop: loop,
    autoplay: true,
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie options={defaultOptions} height={height} width={width} />
  );
};
