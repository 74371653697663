import { styled } from "@material-ui/styles";
import variable from "./../../scss/_variables.scss";
import { Button } from "@material-ui/core";
const LoginRegisterButton = styled(Button)((props) => ({
    color: props.login ? variable.dark02 : variable.white,
    borderColor: props.register && "transparent",
    background: props.login ? variable.white : variable.blue01,
    border: `1px solid`,
    padding: '7px 20px',
    textTransform: 'capitalize',
    fontSize: 18,
    fontWeight: 600,
    transition: '0.8s',
    fontFamily: "'Fira Sans', 'Hind Siliguri', sans-serif",
    borderRadius: 10,
    whiteSpace: 'nowrap',
    '&:hover': {
        color: props.login ? variable.dark02 : variable.white,
        background: props.login ? variable.white : variable.blue01,
    },
    '&:disabled': {
        background: '#ccc'
    }
}))
export default LoginRegisterButton;