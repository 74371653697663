import { Button, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import CustomeModal from '../../components/Custom/CustomModal';
import { AdminTD, AdminTH, AdminTR } from '../../components/Custom/CustomTableComponents';
import Preloader from '../../components/Preloader/Preloader';
import batchService from '../../services/Admin/BatchManagement/batchService';
import courseService from '../../services/Admin/CourseManagement/courseService';


const SemesterSummary = () => {
    const [openModal, setOpenModal] = useState({ edit: false });
    const { data: semesterSummary, isLoading } = useQuery("semester-summary", batchService.getSemesterSummary, {
        cacheTime: 1000 * 60 * 10,
        staleTime: 1000 * 60 * 10,
        select: ({ data }) => data?.data
    })
    const { data: gradePoints, isLoading: gradePointLoading } = useQuery("grade-points", () => batchService.getGradePointList(), {
        cacheTime: 1000 * 60 * 10,
        staleTime: 1000 * 60 * 10,
        select: ({ data }) => data?.data
    })
    const { data: courseDetail, isLoading: courseLoading } = useQuery('publicCourse', () => courseService.getPublicCourseDetail(), {
        cacheTime: 1000 * 60 * 24,
        staleTime: 1000 * 60 * 10,
        select: ({ data }) => data?.data?.course
    });

    if (isLoading || gradePointLoading || courseLoading) {
        return <Preloader />
    }
    return (
        <div className='semester-summary-page pt-5'>
            <div className='container mt-5'>
                <h3 className='text-center gray-title mb-5 pt-3'>1st Batch</h3>

                <div className="overlay-bg d-flex justify-content-between align-items-center">
                    <h3 className='m-0'>Get a better understanding on grading, <span className='text-button' onClick={() => setOpenModal({ edit: true })}>check grade point table</span></h3>
                </div>
                <CustomeModal customStyle={{ maxWidth: 900 }} admin title="Grade Point" editMode openModal={openModal}>
                    <div className='py-5'>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <AdminTR>
                                        <AdminTH align="center" >Overall Marks</AdminTH>
                                        <AdminTH align="center" >Grade</AdminTH>
                                        <AdminTH align="center" >Grade point</AdminTH>
                                    </AdminTR>
                                </TableHead>
                                <TableBody>
                                    {
                                        gradePoints.map(({ upperMark, lowerMark, grade, gradePoint, _id }) => (
                                            <AdminTR key={_id}>
                                                <AdminTD align="center">{lowerMark}-{upperMark}</AdminTD>
                                                <AdminTD align="center">{grade}</AdminTD>
                                                <AdminTD align="center">{gradePoint}</AdminTD>
                                            </AdminTR>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </CustomeModal>
                <div className="overlay-bg d-flex justify-content-between align-items-center">
                    <h3 className='m-0'>Get a proper view on course structure,&nbsp;
                        <Link className='text-button' to={`/course-details/${courseDetail?._id}`}>check course structure
                        </Link></h3>



                </div>
                {/* <h3 className='text-center gray-title mb-5 pt-3'>Semester Overviews</h3> */}
                {
                    semesterSummary?.map(({ courses, _id }) => (
                        <div className="semester-info-card mb-3" key={_id?.semesterId}>
                            <h3 className='gray-title p-4'>{_id?.semesterName}</h3>
                            <div className='p-2 mt-3'>
                                <TableContainer>
                                    <Table sx={{ width: "100%" }}>
                                        <TableHead>
                                            <AdminTR>
                                                <AdminTH >Course Name</AdminTH>
                                                <AdminTH align="center" >Status</AdminTH>
                                                <AdminTH align="center" >Credit</AdminTH>
                                                <AdminTH align="center" >Percentage</AdminTH>
                                                <AdminTH align="center" >Grade</AdminTH>
                                            </AdminTR>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                courses?.map(({
                                                    credit,
                                                    gradePoint,
                                                    name,
                                                    obtainMark,
                                                    status }, index) => (
                                                    <AdminTR key={index}>
                                                        <AdminTD style={{ whiteSpace: "pre-wrap" }}>{name}</AdminTD>
                                                        <AdminTD align="center"><span className={`course-status ${status}`}>{status === "inprogress" ? "in progress" : status}</span> </AdminTD>
                                                        <AdminTD align="center">{credit}</AdminTD>
                                                        <AdminTD align="center">{obtainMark}</AdminTD>
                                                        <AdminTD align="center">{gradePoint}</AdminTD>
                                                    </AdminTR>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SemesterSummary;