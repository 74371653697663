import axios from "axios";
import { detectOS } from "../../utilities/deviceDetect";
import fingerPrintVisitorId from "../../utilities/fingerPrint";
const API_URL = process.env.REACT_APP_API_URL;

// User Token from localStorage
const localStorageData = JSON.parse(localStorage.getItem('user')) || {};
const token = localStorageData && localStorageData['token'];
axios.defaults.headers.common = { 'Authorization': `${token}` }

class UserDeviceInfoService {
    // Post User Device Info
    addUserDeviceInfo = (payload) => {
        return axios
            .post(API_URL + `user-device-info`, payload)
    }

    // Get User Device Info
    getUserDeviceInfo = (payload) => {
        return axios
            .get(API_URL + `user-device-info`, {
                params: {
                    ...payload
                }
            })
    }

    // Delete Single User Device Info
    deleteUserDeviceInfo = (id) => {
        return axios
            .delete(API_URL + `user-device-info/delete/${id}`);
    }

    // Delete All User Device Info
    deleteAllUserDeviceInfo = (payload) => {
        return axios
            .delete(API_URL + `user-device-info/delete-all`, {
                params: {
                    ...payload
                }
            });
    }

    // Get User Device Info By Email
    getUserDeviceInfoByEmail = (payload) => {
        return axios
            .get(API_URL + `user-device-info/search-by-email`, {
                params: {
                    ...payload
                }
            })
    }

    clearDeviceByUser = async () => {
		const token = localStorage.getItem('clearDevice') || "";
		axios.defaults.headers.common = { 'Authorization': `${token}` }
		return axios
			.post(API_URL + `user-device-info/clear-device`,
				{
					platform: detectOS(),
					visitorId: await fingerPrintVisitorId()
				}
			)
			.then(async (response) => {
				if (response.data?.success) {
					await localStorage.setItem(
						"user",
						JSON.stringify(response.data)
					);

				}

				return response.data;
			});

	}
}

export default new UserDeviceInfoService();