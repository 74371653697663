import React, { useEffect } from 'react';
import UserDeviceInfoService from "../../services/UserDeviceInfo/userDeviceInfo";
import { useHistory } from 'react-router-dom';
import deviceClearImage from "../../assets/images/device-clear.png"
import swal from "sweetalert";

// Student clear device
const StudentClearDevice = (props) => {
    document.title = props.name + " - Programming Hero";
    const history = useHistory();

    const handleSubmit = () => {
        swal({
            title: "Are you sure?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((isSure) => {
                if (isSure) {
                    UserDeviceInfoService.clearDeviceByUser()
                        .then(response => {
                            localStorage.removeItem("clearDevice");
                            window.location.href = "/dashboard"
                        }).catch(error => {
                            if (error?.response?.status === 401) {
                                swal("", "This token has been expired, Please try to login again", "error")
                            }
                            else {
                                swal("", error?.response?.data?.message || "Some error occurred", "error")
                            }

                        })
                }
            })
    }

    const logOut = () => {
        history.push("/");
        localStorage.removeItem("clearDevice");
    }

    useEffect(() => {
        if (!localStorage.getItem("clearDevice")) {
            history.push("/");
        }
    }, [])

    return (
        <>

            <div className="my-5 py-4 py-md-5 student-clear-device-component">
                <div className="container">
                    <div className="text-center">
                        <h4 className="mb-5 font-weight-bold">Device Limit Exceeded</h4>
                        <img className="device-clear-image mb-3" src={deviceClearImage} alt="deviceClear" />
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-4">

                        <div className="text-center">
                            <div>Your device limit exceeded. You need to logout from all devices to log in with a new device.</div>
                            <div>Do you want to logout from all devices?</div>
                        </div>

                    </div>
                    <div className="d-flex justify-content-center">
                        <button onClick={() => handleSubmit()} className='btn btn-rounded-green mr-2'>Logout from all</button><button onClick={() => logOut()} className='btn btn-rounded ml-2'>Logout</button>

                    </div>
                </div>



            </div>
        </>
    );
};

export default StudentClearDevice;