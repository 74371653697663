import { Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import variable from './../../scss/_variables.scss';
const NavigateButton = styled(Button)((props) => ({
    background: `${props.previous ? 'transparent' : props.gradient ? variable.blueGradient : variable.primary2}`,
    color: `${props.previous ? variable.grayButton : '#fff'}`,
    padding: `${props.previous ? '5px' : '6px'} 15px`,
    textTransform: 'capitalize',
    fontSize: 16,
    borderRadius: 30,
    border: `${props.previous ? `1px solid ${variable.grayButton}` : 'none'}`,
    minWidth: 120,
    whiteSpace: 'nowrap',
    fontFamily: "'Poppins', sans-serif",
    '@media (max-width:450px)': {
        minWidth: 110,
        padding: '5px 10px',
    },
    '&:hover': {
        background: `${props.previous ? 'transparent' : props.gradient ? variable.blueGradient : variable.primary2}`,
    },
    "&:disabled": {
        background: '#ccc'
    }
}))
export default NavigateButton;