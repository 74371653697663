import { Skeleton } from '@material-ui/lab';
import React from 'react';

// Student Dashboard Skeleton Preloader
const StudentDashBoardSkeletonLoader = ({ tab }) => {
    return (
        <div className="container student-dashboard-skeleton-loader">
            {!tab &&
                <div className='mt-5 pt-5'>
                    <Skeleton animation="wave" variant="rect" className='my-4' width={"65%"} height={25} />
                    <div style={{ overflowX: 'auto' }}>
                        <div className='d-inline-flex justify-content-between mb-4' >
                            {
                                [1, 2, 3].map(item => (
                                    <Skeleton key={item} animation="pulse" className='my-2 radius mr-md-5 mr-1' variant="rect" width={140} height={25} />
                                ))
                            }
                        </div>
                    </div>
                </div>}
            {
                [1, 2, 3].map(item => (
                    <div key={item} className="card shadow single-course border-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3 col-sm-6 order-1">
                                    <div className="course-thumb text-center">
                                        <Skeleton animation="wave" className="skeleton-thumbnail" variant="rect" />
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-12 border-right order-2 order-sm-3 order-md-2 my-3 mt-md-0 mb-sm-0">
                                    <div className='course-info d-flex flex-column justify-content-between h-100'>
                                        <Skeleton animation="wave" variant="rect" className='radius' width={"100%"} height={15} />
                                        <Skeleton variant="rect" className="mt-2 radius" width={"100%"} height={20} />
                                        <Skeleton variant="rect" className="my-2 radius" width={"70%"} height={20} />
                                        <div className='d-flex align-items-center course-content'>
                                            {
                                                [1, 2, 3].map((item) => <div key={item} className='d-flex align-items-center radius ml-3'>
                                                    <Skeleton variant="rect" className="my-2 radius" width={60} height={12} />
                                                </div>)
                                            }
                                        </div>
                                        <div className='d-flex align-items-center course-progress rounded'>
                                            <Skeleton variant="rect" className="my-2 radius" width={"100%"} height={12} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 order-3 order-sm-2 order-md-3">
                                    <div className='next-module-info d-flex flex-column justify-content-between h-100'>
                                        <div>
                                            <Skeleton animation="wave" className='my-2 radius' variant="rect" width={"60%"} height={15} />
                                            <Skeleton animation="pulse" className='my-2 radius' variant="rect" width={"90%"} height={15} />
                                            <Skeleton animation="wave" className='my-2 radius' variant="rect" width={"80%"} height={15} />
                                        </div>
                                        <Skeleton animation="pulse" className="skeleton-btn" variant="rect" width={"100%"} height={42} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

    );
};

export const CourseInfoSkeleton = () => {
    return (
        <div className="col-md-4 col-sm-6 order-3 order-sm-2 order-md-3 student-dashboard-skeleton-loader">
            <div className='next-module-info d-flex flex-column justify-content-between h-100'>
                <div>
                    <Skeleton animation="wave" className='my-2 radius' variant="rect" width={"60%"} height={15} />
                    <Skeleton animation="pulse" className='my-2 radius' variant="rect" width={"90%"} height={15} />
                    <Skeleton animation="wave" className='my-2 radius' variant="rect" width={"80%"} height={15} />
                </div>
                <Skeleton animation="pulse" className="skeleton-btn" variant="rect" width={"100%"} height={42} />
            </div>
        </div>
    )
}

export default StudentDashBoardSkeletonLoader;