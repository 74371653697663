import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
const FINGER_PRINT_JS_TOKEN = process.env.REACT_APP_FINGER_PRINT_JS_API_KEY;
const ENV_TYPE = process.env.REACT_APP_ENV;

const fingerPrintVisitorId = async () => {
    if (ENV_TYPE === 'uat' || ENV_TYPE === 'production') {
        const fp = await FingerprintJS.load({ token: FINGER_PRINT_JS_TOKEN, endpoint: 'https://fp.programming-hero.com' });
        const result = await fp.get();
        return result.visitorId
    }
    else {
        return;
    }
    
}

export default fingerPrintVisitorId;